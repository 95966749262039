import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import useIsMounted from 'hooks/useIsMounted';
import usePreviousValue from 'hooks/usePreviousValue';

import { TOGGLE_DONATE_MODAL } from 'storage/modules/common/actions/toggleDonateModal';

import Title, {
  ALIGN_VARIANTS as TITLE_ALIGNS,
  TAG_VARIANTS as TITLE_TAGS,
} from 'components/atoms/Title/Component';
import Paragraphs, {
  ALIGN_VARIANTS as PARAGRAPHS_ALIGNS,
  SIZE_VARIANTS as PARAGRAPHS_SIZES,
  COLOR_VARIANTS as PARAGRAPHS_COLORS,
} from 'components/atoms/Paragraphs/Component';
import Button, { COLOR_VARIANTS as BUTTON_COLORS } from 'components/atoms/Button/Component';

import useTrackBehaviour from 'components/organisms/Modal/hooks/useTrackBehaviour';
import useActionSize from 'components/blocks/view/hooks/useActionSize';

import { modalContentProps } from '../../../propTypes';

import styles from './ModalContent.module.scss';

const MODAL_ID = 'form-modal-content';

function ModalContent({
  illustration,
  title,
  paragraphs,
  action,
  eventData,
  closeModalHandler,
  disableOutsideCloseHandler,
}) {
  const dispatch = useDispatch();
  const showDonateModal = useSelector((state) => state.common.showDonateModal);
  const prevShowDonateModal = usePreviousValue(showDonateModal);

  const isMounted = useIsMounted();
  const actionSize = useActionSize(isMounted);

  useEffect(() => {
    if (!showDonateModal && prevShowDonateModal) {
      setTimeout(disableOutsideCloseHandler, 0, false);
    }
  }, [showDonateModal, prevShowDonateModal, disableOutsideCloseHandler]);

  const openDonateModal = useCallback(
    (event) => {
      event.preventDefault();
      dispatch({ type: TOGGLE_DONATE_MODAL, shown: true });
      disableOutsideCloseHandler(true);
    },
    [dispatch, disableOutsideCloseHandler],
  );

  useTrackBehaviour({
    containerId: MODAL_ID,
    selector: '.donate-modal',
    externalHandler: openDonateModal,
    eventObj: eventData?.donate,
  });
  useTrackBehaviour({
    containerId: MODAL_ID,
    selector: '.telegram-link',
    eventObj: eventData?.telegram,
  });
  useTrackBehaviour({
    containerId: MODAL_ID,
    selector: '.support-link',
    eventObj: eventData?.support,
  });

  return (
    <div id={MODAL_ID}>
      {illustration && (
        <img className={styles.illustration} src={illustration} alt="" role="presentation" />
      )}
      <Title tag={TITLE_TAGS.div} align={TITLE_ALIGNS.CENTER}>
        {title}
      </Title>
      {paragraphs && (
        <Paragraphs
          className={styles.description}
          align={PARAGRAPHS_ALIGNS.CENTER}
          size={PARAGRAPHS_SIZES.SMALL}
          color={PARAGRAPHS_COLORS.DARK_200}
        >
          {paragraphs}
        </Paragraphs>
      )}
      {action && (
        <Button
          className={styles.action}
          size={actionSize}
          color={BUTTON_COLORS.BASE_DARK}
          onClick={closeModalHandler}
        >
          {action}
        </Button>
      )}
    </div>
  );
}

ModalContent.propTypes = {
  ...modalContentProps,
  closeModalHandler: PropTypes.func.isRequired,
  disableOutsideCloseHandler: PropTypes.func.isRequired,
};

export default ModalContent;
