import { useCallback, useEffect, useRef } from 'react';
import useSendEvent from 'hooks/useSendEvent';

export default function useTrackBehaviour({ containerId, selector, eventObj, externalHandler }) {
  const sendEventCreator = useSendEvent();
  const refsList = useRef([]);

  const trackBehaviour = useCallback(
    (event) => {
      sendEventCreator({ eventObj })();

      if (typeof externalHandler === 'function') {
        externalHandler(event);
      }
    },
    [sendEventCreator, eventObj, externalHandler],
  );

  useEffect(() => {
    refsList.current = [];

    setTimeout(() => {
      const modalContent = document.getElementById(containerId);

      if (!modalContent) {
        return;
      }

      const elementsForTracking = modalContent.querySelectorAll(selector);
      elementsForTracking.forEach((elementForTracking) => {
        elementForTracking.addEventListener('click', trackBehaviour);
        refsList.current.push(elementForTracking);
      });
    }, 200);

    return () => {
      refsList.current.forEach((trackingElement) => {
        trackingElement.removeEventListener('click', trackBehaviour);
      });
    };
  }, [containerId, selector, trackBehaviour]);
}
