import React, { useState, useCallback, useEffect } from 'react';
import cls from 'classnames';

import { CSSTransition } from 'react-transition-group';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import envConfig, { NODE_ENV_VARIANTS } from 'constants/config';

import Button, {
  COLOR_VARIANTS as BUTTON_COLORS,
  TYPE_VARIANTS as BUTTON_TYPES,
} from 'components/atoms/Button/Component';
import LoadingDots from 'components/base/LoadingDots';

import usePreviousValue from 'hooks/usePreviousValue';
import useSendEvent from 'hooks/useSendEvent';

import * as BASE_LOADABLE from 'storage/utilities/baseLoadable';

import sendFormUtil from 'utilities/sendForm';

import { propTypes, defaultProps } from '../propTypes';

import Field from './blocks/Field';

import commonStyles from '../Common.module.scss';
import componentStyles from './Component.module.scss';

export { default as useModalHandlers, STATUSES as MODAL_STATUSES } from './hooks/useModalHandlers';

function SubscribeForm({
  className,
  apiPrefix,
  url,
  type,
  actionSize,
  actionText,
  agreementText,
  fields,
  skipFieldEvents,
  eventData,
  onSuccessSubmit,
  onErrorSubmit,
}) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { control, errors, handleSubmit, reset } = useForm({
    mode: 'onTouched',
  });

  const [statuses, setStatuses] = useState(BASE_LOADABLE.withData.initial());
  const prevLoadingStatus = usePreviousValue(statuses.loading);

  const submitHandler = useCallback(
    (data) => {
      if (statuses.loading) {
        return;
      }

      setStatuses(BASE_LOADABLE.withData.begin(data));
    },
    [setStatuses, statuses.loading],
  );

  const sendEventCreator = useSendEvent();

  useEffect(() => {
    async function sendFormHelper() {
      try {
        sendEventCreator({ eventObj: { ...eventData, type: 'submit-start' } })();

        const token =
          envConfig.NODE_ENV === NODE_ENV_VARIANTS.PRODUCTION &&
          typeof executeRecaptcha === 'function'
            ? await executeRecaptcha('subscribe')
            : 'dev-token';

        const [{ data }] = await Promise.all([
          sendFormUtil({
            apiPrefix,
            url,
            method: 'post',
            data: { type, token, ...statuses.data },
            config: {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            },
          }),
          new Promise((resolve) => setTimeout(resolve, 500)),
        ]);

        setStatuses(BASE_LOADABLE.withData.success());
        onSuccessSubmit(data);

        setTimeout(() => {
          setStatuses(BASE_LOADABLE.withData.initial());
          sendEventCreator({
            eventObj: {
              ...eventData,
              type: data === 'duplicate' ? 'submit-duplicate' : 'submit-success',
            },
          })();
          reset();
        }, 10);
      } catch (error) {
        onErrorSubmit();
        sendEventCreator({
          eventObj: { ...eventData, type: 'submit-error', error: error.message },
        })();
        setStatuses(BASE_LOADABLE.withData.error());
      }
    }

    if (!prevLoadingStatus && statuses.loading) {
      sendFormHelper();
    }
  }, [
    executeRecaptcha,
    apiPrefix,
    url,
    type,
    setStatuses,
    statuses.data,
    statuses.loading,
    prevLoadingStatus,
    reset,
    sendEventCreator,
    eventData,
    onSuccessSubmit,
    onErrorSubmit,
  ]);

  if (!fields.length) {
    return null;
  }

  return (
    <form
      className={cls(commonStyles.SubscribeForm, className)}
      onSubmit={handleSubmit(submitHandler)}
    >
      <div className={commonStyles.fields}>
        {fields.map((field) => (
          <div key={field.name} className={commonStyles.field}>
            <Field
              {...field}
              control={control}
              skipFieldEvents={skipFieldEvents}
              sendEventCreator={sendEventCreator}
              eventData={eventData}
            />
            <CSSTransition
              in={Boolean(errors[field.name])}
              timeout={{ enter: 500, exit: 0, appear: 0 }}
              classNames={{
                enter: componentStyles.errorEnter,
                enterActive: componentStyles.errorEnterActive,
                exit: componentStyles.errorExit,
                exitActive: componentStyles.errorExitActive,
              }}
              unmountOnExit
            >
              <div
                className={componentStyles.fieldError}
                dangerouslySetInnerHTML={{
                  __html: errors[field.name]?.message || 'Поле заполнено неверно',
                }}
              />
            </CSSTransition>
          </div>
        ))}
      </div>
      <div
        className={cls(commonStyles.text, componentStyles.text, componentStyles.agreement)}
        dangerouslySetInnerHTML={{ __html: agreementText }}
      />
      <div className={commonStyles.actionWrapper}>
        <Button
          className={componentStyles.action}
          color={BUTTON_COLORS.BASE_DARK}
          type={BUTTON_TYPES.SUBMIT}
          size={actionSize}
        >
          {actionText}
          {statuses.loading && (
            <div className={componentStyles.loadingOverlay}>
              <LoadingDots isWhite />
            </div>
          )}
        </Button>
      </div>
    </form>
  );
}

SubscribeForm.propTypes = propTypes;
SubscribeForm.defaultProps = defaultProps;

export default SubscribeForm;
