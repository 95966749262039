import React from 'react';
import PropTypes from 'prop-types';

import Portal from './Portal';
import Layout from './Layout';

function Modal(props) {
  const {
    id,
    order,
    open,
    children,
    isFullScreen,
    hideCloseButton,
    disableOutsideClose,
    wrapToContainer,
    hideBackdrop,
    onClose,
    layoutContentClassName,
  } = props;

  if (!open || !children) {
    return null;
  }

  return (
    <Portal id={id} order={order}>
      <Layout
        onClose={onClose}
        isFullScreen={isFullScreen}
        hideBackdrop={hideBackdrop}
        hideCloseButton={hideCloseButton}
        wrapToContainer={wrapToContainer}
        disableOutsideClose={disableOutsideClose}
        contentClassName={layoutContentClassName}
      >
        {children()}
      </Layout>
    </Portal>
  );
}

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  order: PropTypes.number,
  open: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([() => null, PropTypes.func]),
  isFullScreen: PropTypes.bool,
  disableOutsideClose: PropTypes.bool,
  wrapToContainer: PropTypes.bool,
  hideBackdrop: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  layoutContentClassName: PropTypes.string,
};

Modal.defaultProps = {
  children: null,
  order: undefined,
  onClose: undefined,
  layoutContentClassName: undefined,
  isFullScreen: false,
  disableOutsideClose: false,
  wrapToContainer: false,
  hideBackdrop: false,
  hideCloseButton: false,
};

export default Modal;
