import { useMemo } from 'react';

export default function useMemoizedValidation(validation) {
  return useMemo(
    () =>
      Object.entries(validation).reduce((acc, [key, value]) => {
        if (key !== 'pattern') {
          acc[key] = value;
          return acc;
        }

        if (typeof value !== 'object') {
          acc[key] = new RegExp(value);
          return acc;
        }

        if (!value.value) {
          return acc;
        }

        acc[key] = { ...value, value: new RegExp(value.value) };
        return acc;
      }, {}),
    [validation],
  );
}
