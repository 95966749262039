import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import { CSSTransition } from 'react-transition-group';

import Container from 'components/base/Container';
import CloseIcon from 'components/icons/CloseIcon';

import useCloseOnOutsideEvents from 'hooks/useCloseOnOutsideEvents';

import { useLayoutContext, MIN_TABLET_SCREEN } from 'utilities/layoutContext';

import useTouchClosing from './useTouchClosing';

import styles from './Layout.module.scss';

const endTiming = 200;

function Layout(props) {
  const {
    children,
    isFullScreen,
    disableOutsideClose,
    hideBackdrop,
    hideCloseButton,
    onClose,
    contentClassName,
    wrapToContainer,
  } = props;

  const { viewportWidth } = useLayoutContext();
  const isMobileModal = useMemo(() => viewportWidth < MIN_TABLET_SCREEN, [viewportWidth]);

  const [mounted, setMount] = useState(false);
  const contentRef = useRef(null);

  const closeHandler = useCallback(() => {
    setMount(false);
    setTimeout(onClose, endTiming);
  }, [onClose]);

  const touchClosing = useTouchClosing(closeHandler);

  useCloseOnOutsideEvents({
    ref: contentRef,
    callback: closeHandler,
    isOpen: mounted,
    disableBody: !hideBackdrop,
    disableOutsideClose,
  });

  useEffect(() => {
    if (contentRef.current) {
      setMount(true);
    }
  }, []);

  return (
    <div className={styles.Layout}>
      <div className={cls(styles.backdrop, hideBackdrop && styles.transparentBackdrop)} />
      <CSSTransition
        in={mounted}
        timeout={{ enter: 500, exit: endTiming, appear: 0 }}
        classNames={{
          enter: styles.contentEnter,
          enterActive: styles.contentEnterActive,
          exit: styles.contentExit,
          exitActive: styles.contentExitActive,
        }}
      >
        <div
          className={cls(
            styles.content,
            hideBackdrop && styles.withoutBackdrop,
            isFullScreen && styles.fullScreenContent,
            contentClassName,
          )}
          ref={contentRef}
          style={
            touchClosing.closingDiff
              ? { transform: `translateY(${touchClosing.closingDiff}px)` }
              : undefined
          }
        >
          {!isFullScreen && isMobileModal && !hideBackdrop && (
            <div
              className={styles.closeArea}
              onTouchStart={touchClosing.startClosingTouchHandler}
              onTouchEnd={touchClosing.endClosingTouchHandler}
              onTouchMove={touchClosing.moveClosingTouchHandler}
            />
          )}
          <div className={styles.scroll} data-allow-touch-move="true">
            <div className={styles.container}>
              {wrapToContainer ? <Container>{children}</Container> : children}
            </div>
          </div>
          {!hideCloseButton && (
            <button className={styles.closeAction} onClick={onClose}>
              <CloseIcon pathClassName={styles.closeIcon} />
            </button>
          )}
        </div>
      </CSSTransition>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  disableOutsideClose: PropTypes.bool.isRequired,
  wrapToContainer: PropTypes.bool.isRequired,
  hideCloseButton: PropTypes.bool,
  hideBackdrop: PropTypes.bool,
  contentClassName: PropTypes.string,
  onClose: PropTypes.func,
};

Layout.defaultProps = {
  onClose: undefined,
  hideBackdrop: false,
  hideCloseButton: false,
  contentClassName: undefined,
};

export default Layout;
