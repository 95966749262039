import { useEffect, useCallback } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

function useCloseOnOutsideEvents({ ref, callback, isOpen, disableBody, disableOutsideClose }) {
  const { current: domNode } = ref;

  const closeOnOutsideClick = useCallback(
    (event) => {
      if (disableOutsideClose) {
        return;
      }

      if (domNode && !domNode.contains(event.target)) {
        if (callback) {
          callback();
        }
      }
    },
    [disableOutsideClose, domNode, callback],
  );

  useEffect(() => {
    if (isOpen) {
      if (disableBody) {
        disableBodyScroll(domNode, {
          allowTouchMove: (el) => {
            let current = el;
            while (current && current !== domNode) {
              if (current.hasAttribute && current.hasAttribute('data-allow-touch-move')) {
                return true;
              }
              current = current.parentNode;
            }

            return false;
          },
        });
      }
      document.addEventListener('mousedown', closeOnOutsideClick);
      document.addEventListener('touchstart', closeOnOutsideClick);
    } else {
      document.removeEventListener('mousedown', closeOnOutsideClick);
      document.removeEventListener('touchstart', closeOnOutsideClick);
    }
    return () => {
      if (isOpen && disableBody) {
        enableBodyScroll(domNode);
      }
      document.removeEventListener('mousedown', closeOnOutsideClick);
      document.removeEventListener('touchstart', closeOnOutsideClick);
    };
  }, [closeOnOutsideClick, disableBody, domNode, isOpen]);
}

export default useCloseOnOutsideEvents;
