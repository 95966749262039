import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import _noop from 'lodash/noop';

import { fieldPropTypes, fieldDefaultProps } from '../../../propTypes';

import useMemoizedValidation from './useMemoizedValidation';

import styles from './Field.module.scss';

function Field({
  control,
  name,
  label,
  type,
  validation,
  mask,
  skipFieldEvents,
  sendEventCreator,
  eventData,
}) {
  const preparedValidation = useMemoizedValidation(validation);

  const focusHandlerCreator = useCallback(() => {
    if (skipFieldEvents) {
      return _noop;
    }

    return sendEventCreator({ eventObj: { ...eventData, type: 'focus-field', field: name } });
  }, [skipFieldEvents, sendEventCreator, eventData, name]);

  const renderInput = useCallback(
    (field) => {
      const classNames = cls(styles.input, !field.value && styles.emptyInput);

      if (!mask) {
        return (
          <input
            {...field}
            id={field.name}
            type={type}
            className={classNames}
            onFocus={focusHandlerCreator()}
          />
        );
      }

      return (
        <InputMask
          {...field}
          id={field.name}
          className={classNames}
          mask={mask}
          type={type}
          onFocus={focusHandlerCreator()}
          beforeMaskedStateChange={({ currentState, nextState }) => {
            if (
              currentState?.value?.length === 12 ||
              currentState?.value?.length === nextState.value.length - 1
            ) {
              return currentState;
            }

            return nextState;
          }}
        />
      );
    },
    [mask, type, focusHandlerCreator],
  );

  const renderController = useCallback(
    (field, statuses) => {
      const invalid = statuses.isTouched && statuses.invalid;
      const valid = statuses.isTouched && !statuses.invalid;

      return (
        <div
          className={cls(styles.field, invalid && styles.invalidField, valid && styles.validField)}
        >
          {renderInput(field)}
          <label className={styles.label} htmlFor={field.name}>
            {label}
          </label>
        </div>
      );
    },
    [label, renderInput],
  );

  return (
    <Controller
      control={control}
      render={renderController}
      name={name}
      defaultValue=""
      rules={preparedValidation}
    />
  );
}

Field.propTypes = {
  ...fieldPropTypes,
  skipFieldEvents: PropTypes.bool.isRequired,
  sendEventCreator: PropTypes.func.isRequired,
  eventData: PropTypes.shape({}),
};

Field.defaultProps = {
  ...fieldDefaultProps,
  eventData: {},
};

export default Field;
