import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import styles from './CloseIcon.module.scss';

function CloseIcon({ className, pathClassName }) {
  return (
    <svg
      className={cls(styles.CloseIcon, className)}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={cls(styles.path, pathClassName)}
        d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM16.29 14.89C16.3826 14.9826 16.456 15.0925 16.5061 15.2135C16.5562 15.3344 16.582 15.4641 16.582 15.595C16.582 15.7259 16.5562 15.8556 16.5061 15.9765C16.456 16.0975 16.3826 16.2074 16.29 16.3C16.1974 16.3926 16.0875 16.466 15.9665 16.5161C15.8456 16.5662 15.7159 16.592 15.585 16.592C15.4541 16.592 15.3244 16.5662 15.2035 16.5161C15.0825 16.466 14.9726 16.3926 14.88 16.3L12 13.41L9.11 16.3C8.92302 16.487 8.66943 16.592 8.405 16.592C8.14057 16.592 7.88698 16.487 7.7 16.3C7.51302 16.113 7.40798 15.8594 7.40798 15.595C7.40798 15.4641 7.43377 15.3344 7.48387 15.2135C7.53398 15.0925 7.60742 14.9826 7.7 14.89L10.59 12L7.71 9.11C7.61742 9.01742 7.54398 8.90751 7.49387 8.78654C7.44377 8.66558 7.41798 8.53593 7.41798 8.405C7.41798 8.14057 7.52302 7.88698 7.71 7.7C7.89698 7.51302 8.15057 7.40798 8.415 7.40798C8.67943 7.40798 8.93302 7.51302 9.12 7.7L12 10.59L14.89 7.7C14.9826 7.60742 15.0925 7.53398 15.2135 7.48387C15.3344 7.43377 15.4641 7.40798 15.595 7.40798C15.7259 7.40798 15.8556 7.43377 15.9765 7.48387C16.0975 7.53398 16.2074 7.60742 16.3 7.7C16.3926 7.79258 16.466 7.90249 16.5161 8.02346C16.5662 8.14442 16.592 8.27407 16.592 8.405C16.592 8.53593 16.5662 8.66558 16.5161 8.78654C16.466 8.90751 16.3926 9.01742 16.3 9.11L13.41 12L16.29 14.89Z"
      />
    </svg>
  );
}

CloseIcon.propTypes = {
  className: PropTypes.string,
  pathClassName: PropTypes.string,
};

CloseIcon.defaultProps = {
  className: undefined,
  pathClassName: undefined,
};

export default CloseIcon;
