import { useCallback, useState } from 'react';

export default function useTouchClosing(closeHandler) {
  const [closingTouch, setClosingTouchTo] = useState(null);

  const startClosingTouchHandler = useCallback((event) => {
    setClosingTouchTo({ start: event.touches[0].clientY, diff: 0 });
  }, []);
  const endClosingTouchHandler = useCallback(() => {
    setClosingTouchTo(null);
  }, []);

  const moveClosingTouchHandler = useCallback(
    (event) => {
      if (!closingTouch) {
        return;
      }

      const diff = Math.max(0, event.touches[0].clientY - closingTouch.start);
      if (diff > 200) {
        closeHandler();
        endClosingTouchHandler();
        return;
      }

      setClosingTouchTo({ ...closingTouch, diff });
    },
    [closingTouch, endClosingTouchHandler, closeHandler],
  );

  return {
    closingDiff: closingTouch?.diff,
    startClosingTouchHandler,
    endClosingTouchHandler,
    moveClosingTouchHandler,
  };
}
