import axios from 'axios';
import qs from 'qs';

import envConfig from 'constants/config';

export const USE_FAKE_SEND = 'travelee/landings/USE_FAKE_SEND';

async function fakeSend(data) {
  // eslint-disable-next-line no-console
  console.log(qs.stringify(data));
  await new Promise((resolve) => setTimeout(resolve, 1000));

  if (data.email.startsWith('success')) {
    return 'success';
  }

  if (data.email.startsWith('duplicate')) {
    return 'duplicate';
  }

  throw new Error('Something went wrong');
}

export default function sendForm({ apiPrefix, url, method, data, config }) {
  const useFakeSend = localStorage?.getItem(USE_FAKE_SEND) ?? '';

  if (useFakeSend.toLowerCase() === 'true') {
    return fakeSend(data);
  }

  return axios({
    url: `${envConfig[`REACT_APP_${apiPrefix ?? 'CORE_API'}`]}${url}`,
    method,
    data: qs.stringify(data),
    ...config,
  });
}
