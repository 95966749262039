import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import styles from './LoadingDots.module.scss';

function LoadingDots({ isWhite, itemClassName, className }) {
  return (
    <div className={cls(styles.LoadingDots, isWhite && styles.whiteDots, className)}>
      <span className={cls(styles.item, styles.firstItem, itemClassName)} />
      <span className={cls(styles.item, styles.secondItem, itemClassName)} />
      <span className={cls(styles.item, styles.thirdItem, itemClassName)} />
    </div>
  );
}

LoadingDots.propTypes = {
  isWhite: PropTypes.bool,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
};

LoadingDots.defaultProps = {
  isWhite: false,
  className: undefined,
  itemClassName: undefined,
};

export default LoadingDots;
