import { createPortal } from 'react-dom';
import usePortal from './usePortal';

function Portal({ id, order, children }) {
  const target = usePortal(id, order);

  return createPortal(children, target);
}

export default Portal;
