import { useMemo } from 'react';

import { useLayoutContext } from 'utilities/layoutContext';

export default function useBackgroundSource(isMounted, sources) {
  const {
    isMobileLayout,
    isMobileWideLayout,
    isTabletLayout,
    isDesktopLayout,
    isDesktopWideLayout,
  } = useLayoutContext();

  return useMemo(() => {
    if (!isMounted) {
      return null;
    }

    switch (true) {
      case isDesktopLayout:
      case isDesktopWideLayout: {
        return sources?.desktop ?? null;
      }
      case isMobileLayout:
      case isMobileWideLayout: {
        return sources?.mobile ?? null;
      }
      case isTabletLayout: {
        return sources?.tablet ?? null;
      }
      default: {
        return null;
      }
    }
  }, [
    isMounted,
    sources,
    isMobileLayout,
    isMobileWideLayout,
    isTabletLayout,
    isDesktopLayout,
    isDesktopWideLayout,
  ]);
}
