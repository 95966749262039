import React, { useCallback, useMemo, useState } from 'react';

import ModalContent from '../blocks/ModalContent';
import Modal from '../../../Modal';

export const STATUSES = {
  SUCCESS: 'SUCCESS',
  DUPLICATE: 'DUPLICATE',
  ERROR: 'ERROR',
};

export default function useModalHandlers(modals, onCloseModal, enableParentOutsideClose) {
  const [formStatus, changeFormStatusTo] = useState(null);
  const [disableOutsideClose, changeDisableOutsideCloseTo] = useState(false);

  const successSubmitHandler = useCallback((response) => {
    const status = response === 'duplicate' ? STATUSES.DUPLICATE : STATUSES.SUCCESS;
    changeFormStatusTo(status);
  }, []);
  const errorSubmitHandler = useCallback(() => changeFormStatusTo(STATUSES.ERROR), []);

  const closeModalHandler = useCallback(() => {
    changeFormStatusTo(null);

    if (typeof onCloseModal === 'function') {
      onCloseModal(formStatus);
    }
    if (typeof enableParentOutsideClose === 'function') {
      enableParentOutsideClose();
    }
  }, [onCloseModal, enableParentOutsideClose, formStatus]);

  const modalProps = useMemo(() => {
    switch (formStatus) {
      case STATUSES.SUCCESS: {
        return modals.success;
      }
      case STATUSES.DUPLICATE: {
        return modals.duplicate;
      }
      case STATUSES.ERROR: {
        return modals.error;
      }
      default: {
        return null;
      }
    }
  }, [modals, formStatus]);

  const renderModalContent = useCallback(() => {
    if (!modalProps) {
      return null;
    }

    return (
      <ModalContent
        closeModalHandler={closeModalHandler}
        disableOutsideCloseHandler={changeDisableOutsideCloseTo}
        {...modalProps}
      />
    );
  }, [modalProps, closeModalHandler]);

  const renderSubmitResultModal = useCallback(
    () => (
      <Modal
        id="subscribe-form-status-modal"
        order={10}
        open={Boolean(formStatus)}
        onClose={closeModalHandler}
        disableOutsideClose={disableOutsideClose}
      >
        {renderModalContent}
      </Modal>
    ),
    [disableOutsideClose, formStatus, renderModalContent, closeModalHandler],
  );

  return {
    renderSubmitResultModal,
    successSubmitHandler,
    errorSubmitHandler,
  };
}
